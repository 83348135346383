<template>
  <!--Start Navbar-->
  <nav class="navbar navbar-expand-lg fixed-top custom-nav sticky">
    <div class="container">
      <!-- LOGO -->
      <router-link class="navbar-brand brand-logo mr-4" to="/">
        <img
          style="height: 85px"
          src="/assets/images/logo-dark.svg"
          alt=""
          class="img-fluid logo-light"
        />
        <img
          style="height: 85px"
          src="/assets/images/logo.svg"
          alt=""
          class="img-fluid logo-dark"
        />
      </router-link>
      <div
        class="navbar-collapse collapse justify-content-center"
        id="navbarCollapse"
      >
      <ul class="navbar-nav navbar-center" id="mySidenav" v-if="onTerms">
          <li class="nav-item active">
            <router-link to="/">Home</router-link> 

          </li>
          <li class="nav-item">
            <router-link to="/">Features</router-link> 

          </li>
          <li class="nav-item">
            <router-link to="/">About Us</router-link> 
          </li>
          <!-- <li class="nav-item">
                        <a href="#howitworks" class="nav-link">How it Work</a>
                    </li>
                    <li class="nav-item">
                        <a href="#showcase" class="nav-link">Showcase</a>
                    </li>
                    <li class="nav-item">
                        <a href="#price" class="nav-link">Price</a>
                    </li>
                    <li class="nav-item">
                        <a href="#faq" class="nav-link">FAQ's</a>
                    </li> -->
      </ul>
      <ul class="navbar-nav navbar-center" id="mySidenav" v-else>
          <!-- <li class="nav-item active">

            <a href="#home" class="nav-link">Home</a>
          </li>
          <li class="nav-item">

            <a href="#features" class="nav-link">Features</a>
          </li>
          <li class="nav-item">
            <a href="#aboutus" class="nav-link">About Us</a>
          </li> -->
          <!-- <li class="nav-item">
                        <a href="#howitworks" class="nav-link">How it Work</a>
                    </li>
                    <li class="nav-item">
                        <a href="#showcase" class="nav-link">Showcase</a>
                    </li>
                    <li class="nav-item">
                        <a href="#price" class="nav-link">Price</a>
                    </li>
                    <li class="nav-item">
                        <a href="#faq" class="nav-link">FAQ's</a>
                    </li> -->
      </ul>

      </div>
      <div class="contact_btn">
        <a href="mailto:ir@robofloat.com" class="btn btn-sm">CONTACT US</a>
        <button
          class="navbar-toggler ml-2 p-0"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="mdi mdi-menu"></i>
        </button>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
export default {
  name: "medpill_header",
  methods: {},
  props: {
    onTerms: {
      default: false,
      required: true,
    }
  },
};
</script>
