//IMPORT HEADER
import medpill_header from "./components/header";
//IMPORT FOOTER
import medpill_footer from "./components/footer";
//IMPORT INDEX
import medpill_index from "./components/index";
//PARTICLES JS
import "./assets/js/particles.js";
//PARALLAX JS
import "./assets/js/parallax.js";
//OWL CAROUSEL JS
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

export default {
  name: "app",
  data() {
    return{
      onTerms: false,
    }

  },
  created() {
    document.title = "Robofloat";
  },
  components: {
    medpill_header,
    medpill_index,
    medpill_footer,
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.hidePreloader();
      this.initSlider();
      this.initAccordian();
      this.windowScroll();
      this.initElementClick();
      this.initParticleJs();
    },
    /*----ONSCROLL JS-----*/
    windowScroll() {
      var self = this;
      $(window).on("scroll", function () {
        self.initSectionScroll();
      });
    },
    /*----PRELOADER-----*/
    hidePreloader() {
      var preloaderFadeOutTime = 500;
      var preloader = $(".spinner-wrapper");
      setTimeout(function () {
        preloader.fadeOut(preloaderFadeOutTime);
      }, 500);
    },
    /*----SLIDER-----*/
    initSlider() {
      /*----MAIN SLIDER-----*/
      var MainSilderCarousel = $(".main-slider");
      if (MainSilderCarousel.length > 0) {
        MainSilderCarousel.owlCarousel({
          merge: true,
          loop: false,
          nav: false,
          center: false,
          dots: true,
          animateIn: "fadeIn",
          autoplay: true,
          autoplayTimeout: 5000,
          margin: 0,
          items: 1,
        });
      }
      /*----SHOWCASE SLIDER-----*/
      var showcaseCarousel = $(".showcase-slider");
      if (showcaseCarousel.length > 0) {
        showcaseCarousel.owlCarousel({
          center: true,
          items: 5,
          dots: true,
          nav: false,
          loop: true,
          margin: 0,
          responsive: {
            0: {
              items: 2,
            },
            600: {
              items: 3,
            },
            1000: {
              items: 4,
            },
          },
        });
      }

      /*----TESTIMONIAL SLIDER-----*/
      var testimonialCarousel = $(".testimonial-slider");
      if (testimonialCarousel.length > 0) {
        testimonialCarousel.owlCarousel({
          margin: 16,
          loop: true,
          nav: false,
          center: false,
          dots: true,
          autoplay: true,
          autoplayTimeout: 5000,
          items: 3,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: false,
              dots: true,
            },
            600: {
              items: 2,
              nav: false,
              dots: true,
            },
            1000: {
              items: 3,
              nav: false,
              dots: true,
            },
          },
        });
      }
    },
    /*----Element Clicks -----*/
    initElementClick() {
      /*----Menu CLose Click -----*/
      $(".menu-close-btn").on("click", function () {
        $(".navbar-collapse").removeClass("show");
      }),
        /*----Menu Links Click -----*/
        $(".custom-nav .nav-item a").on("click", function (o) {
          var t = $(this);
          $(".nav-item").removeClass("active");
          $(t).parent().addClass("active");
          $("html, body")
            .stop()
            .animate(
              {
                scrollTop: $(t.attr("href")).offset().top - 50,
              },
              1000
            ),
            o.preventDefault();
        }),
        /*----Mobile Menu Click -----*/
        $(document).on("click", ".navbar-collapse.show", function (o) {
          $(o.target).is("a") && $(this).collapse("hide");
        }),
        /*----Back To Top Clicks -----*/
        $(".back_top").on("click", function () {
          return (
            $("html, body").animate(
              {
                scrollTop: 0,
              },
              1e3
            ),
            !1
          );
        });
      /*----Contact & Read More Click -----*/
      $(".main-slider .item a, .contact_btn a").on("click", function (event) {
        var t = $(this);
        $("html, body")
          .stop()
          .animate(
            {
              scrollTop: $(t.attr("href")).offset().top - 50,
            },
            1000
          ),
          event.preventDefault();
      });
    },
    /*----Section Scroll -----*/
    initSectionScroll() {
      var sections = $("section"),
        nav = $(".navbar-nav"),
        nav_height = nav.outerHeight() + 25,
        win_scroll_top = $(window).scrollTop();
      win_scroll_top >= 80
        ? $("nav").addClass("sticky-header")
        : $(".sticky").removeClass("sticky-header");
      win_scroll_top > 100 ? $(".back_top").fadeIn() : $(".back_top").fadeOut();
      /*----ON SCROLL CHANGE ACTIVE MENU-----*/
      var cur_pos = $(window).scrollTop();
      sections.each(function () {
        var top = $(this).offset().top - nav_height,
          bottom = top + $(this).outerHeight();
        if (cur_pos >= top && cur_pos <= bottom) {
          nav.find("li").removeClass("active");
          $(this).addClass("active");
          nav
            .find('a[href="#' + $(this).attr("id") + '"]')
            .parent()
            .addClass("active");
        }
      });
    },

    /*----ACCORDIAN-----*/
    initAccordian() {
      $(".question-card").click(function () {
        if ($(this).hasClass("active")) {
          $(".question-card").removeClass("active");
          $(this).removeClass("active");
        } else {
          $(".question-card").removeClass("active");
          $(this).addClass("active");
        }
      });
    },
    initParticleJs() {
      var ViewportWidth = jQuery(window).width();
      if (ViewportWidth > "992") {
        particlesJS.load(
          "particles-js",
          "assets/particles.json",
          function () {}
        );
        particlesJS.load(
          "particles-js1",
          "assets/particles.json",
          function () {}
        );
      }
    },
  },
};
