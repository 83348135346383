<template>
    <div>
        <!-- Breadcrumb Start-->
        <!-- <div class="section-breadcrumb">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 justify-content-center align-self-center pb-2">
                        <ul class="breadcrumb-link text-center list-unstyled mb-0">
                            <li class="text-white"><a href="#">Home</a></li>
                            <li class="text-white"><a href="#">Pages</a></li>
                            <li class="text-white active">404</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Breadcrumb End -->
        <!-- 404 Section Start-->
        <section class="section" style="height: 50vh;padding-top: 10rem;">
            <div class="container">
                <!-- <img src="assets/images/404.jpg" class="img-fluid mx-auto d-block" alt=""> -->
                <br />
                <h1 class="text-center font-weight-bold section-h1">Sorry, we can't seem find this page.</h1>
                <div class="d-flex justify-content-center mt-4">
                    <router-link to="/" class="theme-btn py-2 px-3">Back To Home</router-link>
                </div>
            </div>

        </section>
        <!-- 404 Section End -->
    </div>
</template>
<script>
    export default {
        name: "pagenotfound",
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.hidePreloader();
            },

            /*----PRELOADER-----*/
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            }

        }
    }</script>
