<template>
    <div style="padding: 5%;padding-top: 10%;" id="main-wrapper">
      <div style="display: flex; flex-direction: row; gap: 1rem">
        <router-link class="navbar-brand brand-logo mr-4" to="/">
          <img
            style="height: 85px"
            src="/assets/images/logo.svg"
            alt=""
            class="img-fluid logo-dark"
          />
        </router-link>
        <h1>
            GENERAL CONDITIONS FOR OPENING AND USING THE RAPID FLOAT ACCOUNT
        </h1>
      </div>
  
      <br>
  

 <h2>1. THE CONTRACT</h2>
 <p>1.1. This Agreement sets out the full terms and conditions (hereinafter referred to as these Terms and Conditions) that will apply to the Loan Account (defined below) that you (defined below) have opened with Cititrust Savings and Loan Liberia (defined below).</p>
    <p>1.2. These Terms and Conditions and any amendments thereto shall take effect from the date of their publication.</p>
  
<h2>2. DEFINITIONS</h2>
<p>2.1. In these terms and conditions, the following words and phrases (unless the context otherwise requires) define the following:</p>
    <p>2.1.1. “Loan Account” or “Rapid Float Account” means a Loan Account held by a Client under the Rapid Float Services with Citirust Savings and Loan Liberia, opened and operated in accordance with these Terms and Conditions;</p>
        <p>2.1.2. "Savings and Loan" refers to Cititrust Savings and Loan Liberia whose head office is located at 20th Street and Tubman Boulevard, Sinkor Monrovia, Liberia, (hereinafter referred to as "Cititrust") Or any other financial institution for the benefit of which it may assign these rights under the legislation in force in Liberia and the regulations of the Central Bank of Liberia;</p>
 <p>2.1.3. " Credit Bureau" means a credit bureau duly authorized under the Central Bank of Liberia to collect and facilitate the sharing of credit information credit to customers;</p>
    <p>2.1.4. "Client" means the person in whose name the Rapid Float account is opened with Cititrust;</p>
 <p>2.1.5. "Customer Service Center" means LCMMMI Branches or other outlets or outlets which may be notified to the Customer from time to time by Cititrust and RoboFloat Liberia;</p>
    <p>2.1.6. "Electronic money" means the value of money available on your MTN Mobile Money LIBERIA account representing an equal amount in cash;</p>
        <p>2.1.7. "Equipment" includes your mobile phone, SIM card and/or other equipment which, when used with others, allows you to access the Network;</p>
            <p>2.1.8. “Rapid Float Menu” refers to the Rapid Float menu on the LCMMMI MOBILE MONEY platform;</p>
                <p> 2.1.9. "MTN MOBILE MONEY Account" means your mobile electronic wallet, proven by LCMMMI of the amount of electronic money you have on the MTN MOBILE MONEY platform;</p>
                    <p>2.1.10. “MTN MOBILE MONEY Service” means the money transfer and payment service provided by MTN MFS CI through the MTN MOBILE MONEY platform;</p>
                        <p>2.1.11. "MTN MOBILE MONEY Platform" means the system operated by MTN MFS CI in LIBERIA for the provision of the LCMMMI MOBILE MONEY Service via the Network;</p>
                            <p>2.1.12. "MTN MOBILE MONEY PIN" means your personal identification number which is the secret code used to access and operate the MTN MOBILE MONEY LIBERIA system and your Rapid Float account;</p>
                                <p>2.1.13. “MTN MOBILE MONEY Subscriber” means a person registered on the MTN MOBILE MONEY platform to send or receive money or make payments;</p>
                                    <p>2.1.14 . "Network" means the mobile telephone network operated by LCMMMI in LIBERIA;</p>
                                        <p>2.1.15. "Request" means any instruction received from the Client through the system by which Cititrust is authorized to act;</p>
                                            <p>2.1.16. “LONESTAR CELL MTN MOBILE MONEY INC” , a limited liability company duly organized under the laws of LIBERIA, having its registered office at LDBI Buidling Congo Town hereinafter referred to as “LCMMMI”;</p>
                                                <p>2.1.17. “Services” includes any banking services or products which the Bank may offer to you under this Agreement and which you may from time to time subscribe to, and “Service” shall be construed accordingly;</p>
                                                    <p>2.1.18. "SIM Card" means the chip which, when used with the appropriate mobile phone, allows you to access the network and use the MTN MOBILE MONEY platform;</p>
                                                        <p>2.1.19. "SMS" is the abbreviation of "Short Message Service" which designates the short message service consisting of a text message transmitted from one mobile phone to another;</p>
                                                            <p>2.1.20. "System" means the Bank's banking and electronic communication software enabling the Client to communicate with the Bank for the purposes of the Services. The system and services will be accessible, under this contract, through the LCMMMI Mobile Money System;</p>
                                                                <p>2.1.21. "Transaction Charges" include the Interest Rate, Set-Up Charge (as defined in clause 5.2.6), Conversion Charges (as defined in clause 5.2.9) and Other Charges and fees payable for the use of the services, as published by the Bank on the Bank's website and/or LCMMMI and/or in daily newspapers in LIBERIA or by any other means determined by the Bank at its sole discretion. Transaction fees may be changed at any time at the sole discretion of the Bank;</p>
                                                                    <p>2.1.22. "We" and "our" mean the Bank and any other assignee hereof;</p>
                                                                        <p>2.1.23. "You" or "your" means the Customer and includes its personal representatives;</p>
                                                                            <p>2.2. The word "Client" must include the male and female gender as well as legal persons;</p>
                                                                                <p>2.3. Words bearing the singular meaning according to the context also have the plural meaning and vice versa.</p>
                                                                                    <p>2.4. The sections of these terms and conditions are provided for general guidance only and do not affect the interpretation of this contract.</p>
  
<h2> 3. ACCEPTANCE OF TERMS AND CONDITIONS</h2>
 <p>3.1. To subscribe to the Rapid Float service through the MTN MOBILE MONEY platform, you must read and understand these terms and conditions which govern the use of the Rapid Float account;</p>
    <p>3.2. If you do not agree with these terms and conditions, please click "Refuse" in the Rapid Float menu;</p>
        <p>3.3. You will be deemed to have read, understood and accepted these terms and conditions:</p>
            <p>3.3.1. By clicking on the "Accept" option on the Rapid Float menu asking you to confirm that you have read, understood, and agreed to be bound by these terms and conditions and/or</p>
                <p>3.3.2. By using or continuing to use and operate the Rapid Float Account;</p>
                    <p>3.4. By subscribing to Rapid Float, you agree to abide by and be bound by these terms and conditions for the time being and, if applicable, by applicable laws governing the operation of the Rapid Float account and you affirm that the terms and conditions set forth herein are without prejudice to any rights of Rapid Float on your account.</p>
                        <p>3.5. The Bank reserves the right to modify these terms at any time and your continued use of your Rapid Float account constitutes your consent to be bound by the terms of such modification.</p>
                            <p>3.6. You acknowledge and agree that the Rapid Float Account is offered electronically and you agree to do business with the Bank and manage the Rapid Float Account only electronically through the Rapid Float Menu on the MTN MOBILE MONEY Platform. Any request for information or complaint concerning the services will be addressed to the Financial partner, by calling 555 or by going to an MTN branch. The Bank takes charge of it and performs the appropriate processing in concert with MTN MOBILE MONEY and Rapid Float. For the avoidance of doubt, you acknowledge and agree that you are not authorized to receive or request services related to the Rapid Float account at any branch of Rapid Float, unless otherwise determined in its sole discretion.</p>
  
<h2> 4. ACCOUNT OPENING</h2>
 <p>4.1. To open a Rapid Float account, you must be at least 18 years old and subscribe to MTN MOBILE MONEY. The Bank reserves the right to verify with LCMMMI the authenticity and status of your MTN MOBILE MONEY account;</p>
    <p>4.2. You can only open a Rapid Float account by an electronic request made from your equipment via the Rapid Float menu of the MTN MOBILE MONEY platform;</p>
        <p>4.3. You agree and authorize Rapid Float to request from LCMMMI, on behalf of MTN MFS CI, all personal information it may hold about you in accordance with the agreement between you and LCMMMI for the provision of services and products of LCMMMI and the MTN MOBILE MONEY service, including your telephone number, your name, your date of birth, the number and type of your identity card and any other information enabling the Bank to identify you and to comply with the regulatory requirements "Know your customer" (set of "personal information"). In addition, you agree and authorize Rapid Float to request from LCMMMI information regarding your use of the MTN MOBILE MONEY service and the MTN MOBILE MONEY system, as required by the Bank, to provide the Services to you ("MTN MOBILE MONEY Information MONEY"). You hereby consent to the disclosure of your personal information and MTN MOBILE MONEY information to the Bank for the above-mentioned use of your personal information and MTN MOBILE MONEY information to the Bank;</p>
            <p>4.4. You consent and authorize the Bank to obtain your personal information from any official source in LIBERIA; and you also agree and consent to the disclosure and communication of such personal information by the Government of LIBERIA to the Bank;</p>
                <p>4.5. You acknowledge and authorize the Bank to compare your personal information received from LCMMMI in accordance with clause 4.3 with that of the Government of LIBERIA which relates to you;</p>
                    <p>4.6. The Bank reserves the right to request additional information about your Rapid Float account application at any time. Lack of information required by microfinance may result in the Bank refusing to accept your application for a Rapid Float account;</p>
                        <p>4.7. Acceptance of your request to open a Rapid Float account by the Bank must be made electronically and sent to the LCMMMI mobile phone number associated with your MTN MONEY account. You acknowledge that the Bank's approval of the opening of your Rapid Float account does not create any contractual relationship between you and LCMMMI beyond the general conditions that apply to your MTN MOBILE MONEY account over time;</p>
                            <p>4.8. The Bank reserves the right to refuse your request to open a Rapid Float account or to revoke it at any time at its sole discretion and without giving any reason.</p>
  
<h2> 5. ACCOUNT TYPES</h2>
<p> As a Rapid Float account holder, you will be entitled, subject to these terms and conditions, to have a Rapid Float loan account.</p>
 <p>5.1. Rapid Float Loan Account: As a Rapid Float account holder, you may, subject to these terms and conditions, apply for a loan via the Rapid Float menu in your equipment;</p>
    <p> 5.2. When you apply for a loan, your application will be assessed in accordance with the Bank's loan assessment process. The Bank reserves the right, at its sole discretion and without giving any reason, to approve or reject your loan application;</p>
        <p>5.3. Subject to approval of your loan application, the Bank will disburse a loan of an amount that it itself will decide, a loan of a minimum amount of 100 USD and a maximum amount of 1,000 USD or such other minimum or maximum amount as Microfinance may determine from time to time in its sole discretion (the "Loan");</p>
            <p>5.4. The loan amount will be credited to your MTN MOBILE MONEY account, subject to any applicable transaction fee deductions;</p>
                <p>5.5. You must repay the loan within one (1) calendar day from the date of loan disbursement;</p>
                    <p>5.6. In return for the loan granted by the Bank, you will have to pay:</p>
                        <p>5.6.1. Service charges of 0.294%, relating to the maintenance of the account intended to receive the withdrawals made for the principal amortization of the loan, the payment of interest and other charges.</p>
                            <p>5.6.2 An interest rate of 0.036% is applied to the 24-hour loan;</p>
                                <p>5.6.3 A penalty of 0.33% of the amount remaining due will be applied per day of late payment up to the 7th day of non-payment, due to non-execution of the payment on the due date;</p>
                                    <p>5.7. The Bank shall have the right to terminate this Agreement and close your Rapid Float Account in accordance with the provisions of Section 13, without prejudice to its rights under this Agreement, if you fail to repay the Loan and/or Transaction Fees within sixty (60) calendar days from the date of loan disbursement;</p>
                                        <p>5.8. The Bank reserves the right to modify the conditions of the Loan, including the commissions paid to it, taking into account the rules and regulations in force in the microfinance sector in the WAEMU zone;</p>
                                            <p>5.9. The Bank, in partnership with LCMMMI, will have a right of pledge and will dispose of the funds you hold in your MTN Mobile Money account;</p>
                                                <p>5.10. You consent and expressly authorize the Bank to disclose, respond to, advise, exchange, and communicate details or information regarding your Rapid Float account to the credit bureau, as required by law.</p>
  
<h2> 6. DESCRIPTION OF THE PROCESS FOR USING THE Rapid Float LOAN SERVICE:</h2>
<p> Using the Rapid Float service includes the following steps:
 - Step 1: subscription;
 It consists of subscribing to the Float loan service by dialing the syntax *156#, then selecting point 9 “Savings and Loan”, then selecting point 1 “Rapid Float” again. Subsequently, the terms and conditions must be accepted by selecting point 1 "Terms and conditions".
 - Step 2: Float request;
 It consists, once subscribed and eligible, to dial the syntax *156#, then to select point 9 "Savings and Loan", then to select again point 1 "E-Money Request".
 - Step 3: Float Reimbursement;
 It consists, after having borrowed Float, in dialing the syntax *156#, then in selecting point 9 “Savings and Loan”, then in again selecting point 2 “Repayment”.
  </p>
  
<h2> 7. COSTS</h2>
 <p>7.1. You hereby agree to pay all transaction fees arising from the use of the Services;</p>
    <p>7.2. You must reimburse LCMMMI for the costs related to the use of the services and LCMMMI has the right to deduct from your Rapid Float account (without informing you in advance):</p>
        <p>7.2.1. All transaction fees related to the Services;</p>
            <p>7.2.2. All legal expenses, including attorney's and client's fees incurred by the Bank for obtaining legal advice on behalf of your Rapid Float account and transactions incurred by you for any legal, arbitration or other proceedings arising out of any transaction with your Rapid Float account; And</p>
                <p>7.2.3. All other fees, expenses and taxes, charges and expenses incurred in fulfilling your requests;</p>
                    <p>7.3. You hereby agree to pay the costs and expenses incurred by the Bank in obtaining or attempting to obtain payment of any loan due to your Rapid Float loan account;</p>
  
<h2> 8. STATEMENTS</h2>
 <p>8.1. You can send a request via USSD for a statement or activity report regarding your Rapid Float account via the "Mini Statement" option;</p>
    <p>8.2. A Rapid Float mini statement shall provide details of the last 6 (six) transactions in your Rapid Float account initiated through your Equipment;</p>
        <p>8.3. The RAPIDFLOAT mini statement will not be sent to you in paper format, but rather by SMS to the LCMMMI mobile phone number associated with your MTN MOBILE Money. You will be invoiced for the costs resulting from this action initiated by LCMMMI upon receipt of your mini statement via Rapid Float;</p>
            <p>8.4. Except for manifest error, a Rapid Float mini statement or Bank statement sent to you concerning your Rapid Float account constitutes definitive proof of the transactions carried out on your Rapid Float account for the period covered by the Rapid Float mini statement.</p>
  
<h2> 9. ABSOLUTE POWER TO Rapid Float</h2>
 <p>9.1. You irrevocably authorize the Bank to process all requests for reimbursement received from you (or allegedly from you) through the system and to hold you responsible for them, even if such requests are not authorized by you or made by you or non-compliant with any existing mandates given by you.</p>
    <p>9.2. The Bank may, at its sole discretion, and after having received a request from the client, cancel any transaction or instruction, but without, however, undertaking to do so;</p>
        <p>9.3. The Bank will have the right to accept and process any request, even if this request is incomplete or ambiguous, for whatever reason, it may, at its absolute discretion, correct this information without however referring to you in the event of need ;</p>
            <p>9.4. The Bank is authorized to make decisions regarding your Rapid Float account pursuant to a court order or a competent authority or body under applicable law;</p>
                <p>9.5. In the event of any conflict between the terms of an application received by the Bank from you and these terms and conditions, these terms and conditions shall prevail.</p>
  
<h2> 10. CUSTOMER EQUIPMENT AND RESPONSIBILITIES</h2>
 <p>10.1. You must, at your own expense, provide and maintain your Equipment in safe and efficient operating condition to access the System and Services;</p>
    <p>10.2. You will be responsible for the proper functioning of your equipment. The Bank shall not be liable in any way for errors or failures caused by your equipment, computer viruses or related problems that may be associated with the use of the System, Services and Equipment. You are responsible for the fees of any service providers and the Bank cannot be held responsible for any losses or delays caused by these providers;</p>
        <p>10.3. You must follow all instructions, procedures and conditions contained in these General Conditions and all documents provided by the Bank regarding the use of the System and the Services;</p>
            <p>10.4. You agree and acknowledge that you are solely responsible for the safekeeping and proper use of your equipment to maintain the secrecy and security of your MTN MOBILE MONEY PIN Code. You must ensure that your MTN MOBILE MONEY PIN Code is not known or in the possession of any third party. The Bank shall not be liable for the disclosure of your MTN MOBILE MONEY PIN to any third party and you hereby agree that the Bank shall not suffer any loss resulting from any information regarding the MTN MOBILE MONEY PIN;</p>
                <p>10.5. You must take all necessary precautions to detect any unauthorized use of the System and Services. To this end, you must ensure that all communications from the Bank are reviewed and verified by you or on your behalf as soon as possible after receipt, so that any unauthorized use and access to the system is detected;</p>
                    <p>10.6. You must inform the Bank immediately through the Customer Service Center if:</p>
                        <p>10.6.1. You have reason to believe that your MTN MOBILE MONEY PIN is or may be known to anyone not authorized to know it and/or has been compromised; and or</p>
                            <p>10.6.2. You have reason to believe that unauthorized use of the Services is or may be occurring and/or that a transaction may have been fraudulently captured or compromised;</p>
                                <p>10.7. You must always follow the security procedures notified to you by the Bank from time to time or any other procedure applicable to the Services. You acknowledge that any failure on your part to follow recommended security procedures may result in a breach of confidentiality of your Rapid Float account. Simply put, you must ensure that the Services are not used, requests are not made or the relevant functions are not performed by anyone other than the person authorized to do so;</p>
                                    <p>10.8. You must not at any time exploit or use the Services in any way that could be detrimental to the Bank.</p>
  
<h2> 11. WARNING</h2>
 <p>11.1. The Bank shall not be liable for any losses you incur in the event of disruption or unavailability of the Service due to: (a) failure of your equipment, or (b) any other circumstances beyond the scope of the Bank, but not limited to, force majeure or error, interruption, delay or unavailability of the system, terrorist action or any action by an enemy, equipment failure, load shedding, adverse weather or atmospheric conditions and failure of any public or private telecommunications system;</p>
    <p>11.2. the Bank will not be held liable for any loss or damage you may suffer as a result of or for any reason related to:</p>
        <p>11.2.1 The unavailability of sufficient funds in your MTN MOBILE MONEY account and/or your Rapid Float account;</p>
            <p>11.2.2 Failure, malfunction, interruption or unavailability of the system, your equipment, the network, the MTN MOBILE MONEY system and/or the MTN MOBILE MONEY service. In such cases, the MTN MOBILE MONEY Service Terms and Conditions apply;</p>
                <p>11.2.3 Money available in your Rapid Float Account is subject to any legal process or other restrictions preventing payment, transfer or withdrawal;</p>
                    <p>11.2.4. Your failure to give proper or adequate instructions for payment or transfer/withdrawal relating to your Rapid Float account</p>
                        <p>11.2.5. Any fraudulent or illegal use of the Services, the System and/or your equipment; Or</p>
                            <p>11.2.6 Your failure to comply with these Terms and Conditions and any other document or information provided by the Bank in connection with the use of the System and Services;</p>
                                <p>11.3. If, for a reason other than those mentioned in paragraphs 11.1 and 11.2, interference interferes with services which are therefore not available, it is the responsibility of the Bank, under this Agreement, to restore them as far as possible. ;</p>
                                    <p>11.4. Except as otherwise provided in this Section 10.3, the Bank shall not be liable for any other impediment or unavailability of the Services, for any reason whatsoever;</p>
                                        <p>11.5. The Bank cannot under any circumstances be held liable for loss of profit, expected savings, losses incurred, indirect or consequential, of any nature whatsoever, resulting from or related to the Services, even if the possibility of these loss or damage is notified to the Bank;</p>
                                            <p>11.6 All statutory warranties and obligations are fully excluded to the extent permitted by law.</p>
  
<h2> 12. INTELLECTUAL PROPERTY RIGHTS</h2>
 You acknowledge that the intellectual property rights of the system (modifications, updates, or improvements thereof from time to time) and any documentation made available to you by Rapid Float via its system remain the property of the Bank and/or his partners. You must not interfere with these intellectual property rights in any way. You may not duplicate, reproduce or modify the system and the associated documentation without prior written notification from the Bank.
  
<h2> 13. COMPENSATION CLAUSE</h2>
 <p> 13.1. The Bank undertakes to respect your requirements or requests concerning your Rapid Float account. You undertake to indemnify the Bank and protect it against any loss, charge, damage, expense or claim that it incurs or may incur. That being said, you exclude the Bank from any liability for any loss or damage that you may suffer as a result of the latter's actions in application of your recommendations in accordance with these General Conditions;</p>
    <p>13.2. The indemnification clause in clause 13.1 also applies to:</p>
        <p>13.2.1 Queries, claims, actions, losses and damages of any kind which may be brought against the Bank or of which it may be a victim or incurred as a result of its action or not on the basis of a request or a malfunction resulting from or failure or unavailability of any computer hardware, software or equipment, loss or destruction of data, unloading, corruption of storage media, natural phenomena, riots, acts of vandalism, sabotage, terrorism, any other event independent of the will of the Bank, interruption or disruption of communication links or resulting from the dependence of any person or any inaccurate, illegal, incomplete information or information contained in a request sent and received by the Bank;</p>
            <p>13.2.2 Any loss or damage which may arise from your use, misuse, abuse or possession of any third party software, including but not limited to any operating system, browser software or other software or program ;</p>
                <p>13.2.3 Any unauthorized access to your Rapid Float account or any breach of security, destruction of or access to your data or any destruction, theft or damage to any of your equipment;</p>
                    <p>13.2.4 Any loss or damage caused by your failure to comply with the following Terms and Conditions and/or the dissemination of inaccurate information or by loss or damage caused by the malfunction or unavailability of a third-party system or by the inability of a third party to process a transaction or any other loss that may be incurred by Rapid Float because of a breach of these Terms and Conditions.</p>
  
<h2> 14. CLAUSE ON MODIFICATION AND TERMINATION OF THE CONTRACT</h2>
 <p> 14.1 The Bank may, at any time, send you a notice terminating or modifying its commercial relationship with you concerning the closure of your Rapid Float account in a particular way. Without generally prejudicing the foregoing, the Bank may cancel the credits granted and demand repayment of the debts resulting from this measure within a period falling within its responsibility;</p>
    <p>14.2 Without prejudice to the Bank's rights set out in Article 14.1, the Bank may, at its sole discretion, suspend or close your Rapid Float account:</p>
        <p>14.2.1 If you use the Rapid Float account for unauthorized purposes or if the Bank detects abuse or misuse, content infringement, fraud or attempted fraud related to your use of the Services;</p>
            <p>14.2.2 If your MTN MOBILE MONEY account or contract with LCMMMI is terminated for any reason whatsoever;</p>
                <p>14.2.3 If the Bank comes or is required to comply with any order, instruction or recommendation of the government, court, regulator or any other competent authority,</p>
                    <p>14.2.4 in the event that the Bank suspects that you have breached these terms and conditions (including non-payment of the loan amount owed by you, if any);</p>
                        <p>14.2.5 Where such suspension or modification is necessary due to technical problems or for security reasons;</p>
                            <p>14.2.6 To facilitate the updating or upgrading of components or features of the Services from time to time;</p>
                                <p>14.2.7 When you remain inactive for a period determined at the sole discretion of the Bank;</p>
                                    <p>14.2.8 If the Bank decides to suspend or cease to provide Services for business reasons or for any other reason that the Bank may determine in its sole discretion;</p>
                                        <p>14.3 You can close your Rapid Float account at any time via the LCMMMI customer service centre;</p>
                                            <p>14.4 If it appears that your Rapid Float account has a credit balance at the time of closure, the Bank will refund this balance to you, less any applicable fees. If, at the time of closing your Rapid Float account, your Rapid Float loan account is in debit, you agree to immediately pay the Bank all the amount due;</p>
                                                <p> 14.5 Termination shall not, however, affect the acquired rights and obligations of either party;</p>
                                                    <p>14.6 In the event that the Bank receives notification of your death, the Bank will not be obliged to authorize any transaction or withdrawal from your Rapid Float account by a third party, except on presentation of letters of administration or power of attorney from your representatives. or legal persons duly designated by the Court of Justice;</p>
  
<h2> 15. INFORMATION CLAUSE</h2>
 <p>15. You expressly agree and authorize the Bank to publish, store, receive or use your personal information or information relating to your Rapid Float account and any other details relating to the use of the Services:</p>
    <p>15.1.1 To and from any national or international law enforcement agency or competent regulatory or government agency to assist in the prevention, detection, investigation or prosecution of crime or fraud;</p>
        <p>15.1.2 To service providers, resellers, agents or any other company of the Bank which may be or become the subsidiary or finance company of the Bank for appropriate business purposes in connection with the Services;</p>
            <p>15.1.3 At a credit bureau;</p>
                <p>15.1.4 To lawyers, auditors or other professional advisers to the Bank or any court or arbitration tribunal in legal or audit proceedings;</p>
                    <p>15.1.5 LCMMMI as part of the MTN MOBILE MONEY service and other services;</p>
                        <p>15.1.6. For appropriate business purposes related to the use of the Services, such as marketing and research activities; And</p>
                            <p>15.1.7. In business practices, including but not limited to quality control, training, and the effective operation of systems.</p>
  
<h2> 16. OTHER PROVISIONS</h2>
 <p>16.1 These Terms and Conditions (which may from time to time be subject to change) constitute a legal agreement which applies to you and your direct heirs;</p>
    <p>16.2 This Agreement and any rights or obligations hereunder do not apply to you or any other person;</p>
        <p>16.3 The Bank may change or modify at any time and without notice these general terms and conditions as well as the transaction fees. These modifications and/or changes may be published on posters or brochures available in LCMMMI branches, in daily newspapers, on the Bank's and/or LCMMMI's website and/or by any other means determined by the Bank. All changes and modifications will become effective 3 months after posting.</p>
            <p>16.4 No failure or delay caused by you or the Bank in exercising any right or remedy hereunder may be waived or by the single or partial exercise of any right or remedy against any other exercise or remedy of a right;</p>
                <p>16.5 The rights and remedies provided herein are cumulative and do not exclude any rights and remedies provided by law;</p>
                    <p>16.6 If any provision of these Terms and Conditions is considered by a duly appointed arbitrator, arbitral tribunal or administrative body of competent jurisdiction as to invalidate or render unenforceable, the invalidity or unenforceability of such provision does not affect the other provisions contained in this document;</p>
                        <p>16.7 Any changes or modifications to these Terms and Conditions may be made from time to time by Rapid Float. Notice of any changes or modifications will be sent to you by posting as provided in sub-paragraph 16.3, and will apply in the same manner as those contained in the Terms and Conditions.</p>
  
<h2> 17. PRIOR NOTICE</h2>
 <p>17.1 The Bank may send you information about your Rapid Float account by SMS to the LCMMMI mobile phone number associated with your LCMMMI MOBILE MONEY account;</p>
    <p>17.2. No claim may be addressed to the Bank for damages resulting from losses, delays, misunderstandings, mutilations, duplications, or any other irregularity due to the transmission of any communication relating to the Rapid Float account.
  </p>

  <h2> 18. DISPUTE RESOLUTION, JURISDICTION AND ARBITRATION CLAUSE</h2>
 <p>18.1 You can contact the LCMMMI customer service center to report any dispute, claim or discrepancy relating to the Rapid Float account;</p>
    <p>18.2 Any dispute arising out of or related to this Agreement which has not been resolved by representatives of the LCMMMI Customer Service Center shall be submitted to arbitration by a single judge appointed by mutual agreement. by the parties or in the absence of this contract, 60 days from the notification of the dispute, at the request of one of the parties, by the president of the arbitration and dispute resolution tribunal, which will be the last resort;</p>
        <p>18.3 All disputes will be settled in accordance with the standards and procedures governing the Commercial Court of Liberia;</p>
            <p>18.4 This Agreement shall be governed by and construed in accordance with the laws of the Republic of LIBERIA.</p>
  
                
  </div>
  </template>
  
<script>
export default {
  name: "termsEn",
  mounted() {
            this.init();
        },
        methods: {
            init() {

                this.scrollToTop();
            },
            scrollToTop() {
            window.scrollTo(0, 0);
            },

            /*----PRELOADER-----*/
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            }

        }

};

</script>