import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { createApp } from "vue";
import { createRouter, createWebHistory} from "vue-router"
import app from "./app.vue";
import LandingPage from "./views/landing.vue";
import pagenotfound from './components/404'
import TermsAndConditions from "./views/termsAndConditions";
import TermsAndConditionsLiberia from "./views/termsLiberia";

const routes = [
    { path: '/', name: 'LandingPage', component: LandingPage },
    { path: '/ci-termsandconditions', name:'TermsFr', component: TermsAndConditions },
    { path: '/lib-termsandconditions', name:'TermsEn', component:TermsAndConditionsLiberia },
    {
      path: '/:catchAll(.*)*',
      name: 'pagenotfound',
      component: pagenotfound
  },

];

  const router = createRouter({
    history: createWebHistory(),
    routes, 
  })

createApp(app).use(router).mount("#app");
