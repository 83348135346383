<template>
  <div style="padding: 5%;padding-top: 10%;" id="main-wrapper">
    <div style="display: flex; flex-direction: row; gap: 1rem">
      <router-link class="navbar-brand brand-logo mr-4" to="/">
        <img
          style="height: 85px"
          src="/assets/images/logo.svg"
          alt=""
          class="img-fluid logo-dark"
        />
      </router-link>
      <h1>
        CONDITIONS GENERALES D’OUVERTURE ET D’UTILISATION DU COMPTE RAPIDE FLOAT
      </h1>
    </div>

    <br>

  <h2>1. LE CONTRAT</h2>
  <p>
    1.1. Le présent contrat définit les termes et conditions générales complètes
    (ci-dessous dénommées les présentes conditions générales) qui s'appliqueront
    au compte de prêt (défini ci-dessous) que vous (défini ci-dessous) avez
    ouvert avec OMIS Finances (défini ci-après).
  </p>
  <p>
    1.2. Ces conditions générales et toutes leurs modifications prendront effet
    à compter de leur date de publication.
  </p>

  <h2>2. DEFINITIONS</h2>
  <p>
    2.1. Dans ces termes et conditions, les mots et expressions suivantes (Sauf
    si le contexte l'exige autrement) définissent les éléments suivants :
  </p>
  <p>
    2.1.1. "Compte de prêt" ou "compte Rapide Floate" désigne un compte de prêt
    détenu par un client dans le cadre des services Rapide Float auprès de OMIS
    Finances, ouvert et géré conformément aux présentes conditions générales ;
  </p>
  <p>
    2.1.2. "Banque" désigne OMIS Finances dont le siège social est situé
    Yopougon, Siège de l'Église Protestante Baptiste OEuvres et Missions
    Internationale, (ci-après dénommé "OMIS Finances") Ou toute autre
    institution financière au bénéfice de laquelle elle peut céder ces droits en
    vertu de la législation en vigueur en Cote d’Ivoire et de la réglementation
    de la Banque centrale des États de l'Afrique de l’Ouest;
  </p>

  <p>
    2.1.3. "Bureau d'information sur le crédit" désigne un bureau d'information
    sur le crédit dûment autorisé en vertu de la Loi uniforme sur les bureaux
    d'information sur le crédit de l'UMOA pour collecter et faciliter le partage
    d'informations sur le crédit aux clients ;
  </p>
  <p>
    2.1.4. "Client" désigne la personne au nom de laquelle le compte Rapide
    Float est ouvert avec OMIS Finances ;
  </p>
  <p>
    2.1.5. "Centre de service clientèle" désigne les agences MTN COTE D’IVOIRE
    ou d'autres points de vente ou points de vente qui peuvent être notifiés au
    client de temps à autre par OMIS Finances et RoboFloat CI ;
  </p>
  <p>
    2.1.6. "Monnaie électronique" désigne la valeur d’argent disponible sur
    votre compte MTN Mobile Money COTE D’IVOIRE représentant un montant égal en
    espèces ;
  </p>
  <p>
    2.1.7. "Équipement" comprend votre téléphone mobile, votre carte SIM et / ou
    tout autre équipement qui, utilisé avec d’autres, vous permet d’accéder au
    réseau ;
  </p>
  <p>
    2.1.8. "Menu Rapide Float" fait référence au menu Rapide Float sur la
    plate-forme de MTN CÔTE D’IVOIRE MOBILE MONEY;
  </p>
  <p>
    2.1.9. "Compte MTN MOBILE MONEY" signifie votre portefeuille électronique
    mobile, prouvé par MTN CÔTE D’IVOIRE du montant d'argent électronique que
    vous possédez sur la plate-forme MTN MOBILE MONEY ;
  </p>
  <p>
    2.1.10. "Service MTN MOBILE MONEY" désigne le service de transfert d'argent
    et de paiement fourni par MTN MFS CI via la plateforme MTN MOBILE MONEY;
  </p>
  <p>
    2.1.11. "Plate-forme MTN MOBILE MONEY" désigne le système exploité par MTN
    MFS CI en Côte d’Ivoire pour la fourniture du service MTN CÔTE D’IVOIRE
    MOBILE MONEY via le réseau ;
  </p>
  <p>
    2.1.12. "PIN MTN MOBILE MONEY" désigne votre numéro d'identification
    personnel qui est le code secret utilisé pour accéder et exploiter le
    système MTN MOBILE MONEY CÔTE D’IVOIRE et votre compte Rapide Float;
  </p>
  <p>
    2.1.13. "Abonné MTN MOBILE MONEY" signifie une personne enregistrée sur la
    plateforme MTN MOBILE MONEY pour envoyer ou recevoir de l'argent ou
    effectuer des paiements ;
  </p>
  <p>
    2.1.14. "Réseau" désigne le réseau de téléphonie mobile exploité par MTN
    CÔTE D’IVOIRE en CÔTE D’IVOIRE;
  </p>
  <p>
    2.1.15. "Requête" signifie toute instruction reçue du client via le système
    par lequel OMIS Finances est autorisée à agir ;
  </p>
  <p>
    2.1.16. "MTN Côte d’Ivoire”, société à responsabilité limitée dûment
    constituée conformément aux lois de la République de Côte d’Ivoire, dont le
    siège social est situé au 12, Avenue Crosson Duplessis, Immeuble Loteny,
    Abidjan Plateau, ci-après dénommé " (MTN CI)" ;
  </p>
  <p>
    2.1.17. “MTN MOBILE FINANCIAL SERVICES CÔTE D’IVOIRE”, société à
    responsabilité limitée dûment constituée conformément aux lois de la Côte
    d’Ivoire, ayant son siège social au Boulevard Valery Giscard d'Estaing,
    Contre allée Europ Auto, Immeuble Prestige, 11 BP 2133 Abidjan 11 ci-après
    dénommé « Etablissement de monnaie électronique » ou « MTN MFS CI »;
  </p>
  <p>
    2.1.18. « Services » comprend tous de services bancaires ou de produits que
    la Banque peut vous proposer en vertu du présent Contrat et, auxquels vous
    pouvez de temps à autre souscrire, et « Service » doit être interprété en
    conséquence ;
  </p>
  <p>
    2.1.19. "Carte SIM" désigne la puce qui lorsqu'elle est utilisée avec le
    téléphone mobile approprié, vous permet d'accéder au réseau et d'utiliser la
    plate-forme MTN MOBILE MONEY ;
  </p>
  <p>
    2.1.20. "SMS" est l’abréviation de « Short Message Service » qui désigne le
    service de messages courts composé d'un message texte transmis d'un
    téléphone mobile à un autre ;
  </p>
  <p>
    2.1.21. "Système" désigne le logiciel bancaire et de communication
    électronique de la Banque permettant au client de communiquer avec la Banque
    aux fins des Services. Le système et les services seront accessibles, dans
    le cadre du présent contrat, par le biais du Système MTN Côte d’Ivoire
    Mobile Money ;
  </p>
  <p>
    2.1.22. Les "frais de transaction" comprennent le taux d'intérêt, les frais
    d'établissement (tels que définis à la clause 5.2.6), les frais de
    conversion (tels que définis à la clause 5.2.9) et les autres frais et
    redevances payables pour l'utilisation des services, tels que publiés par la
    Banque sur le site Web de la Banque et/ou MTN CÔTE D’IVOIRE et/ou dans les
    quotidiens en Côte d’Ivoire ou par tout autre moyen déterminé par la Banque
    à sa seule discrétion. Les frais de transaction peuvent être modifiés à tout
    moment à la seule discrétion de la Banque ;
  </p>
  <p>
    2.1.23. "Nous" et "notre" désignent la Banque et tout autre cessionnaire des
    présentes ;
  </p>
  <p>
    2.1.24. "Vous" ou "votre" désigne le client et inclut ses représentants
    personnels;
  </p>
  <p>
    2.2. Le mot "Client" doit inclure le genre masculin et féminin ainsi que les
    personnes morales ;
  </p>
  <p>
    2.3. Les mots portant le sens singulier selon le contexte ont également le
    sens pluriel et inversement.
  </p>
  <p>
    2.4. Les sections de ces termes et conditions sont fournies à titre
    indicatif seulement et n’affectent pas l’interprétation du présent contrat.
  </p>

  <h2>3. ACCEPTATION DES TERMES ET CONDITIONS</h2>
  <p>
    3.1. Pour souscrire au service Rapide Float via la plate-forme MTN MOBILE
    MONEY, vous devez lire et comprendre les présentes conditions générales qui
    régissent l'utilisation du compte RAPIDE FLOAT ;
  </p>
  <p>
    3.2. Si vous n'êtes pas d'accord avec ces termes et conditions, veuillez
    cliquer sur "Refuser" dans le menu RAPIDE FLOAT ;
  </p>
  <p>
    3.3. Vous serez réputé avoir lu, compris et accepté ces termes et conditions
    :
  </p>
  <p>
    3.3.1. En cliquant sur l'option "Accepter" du menu RAPIDE FLOAT vous
    demandant de confirmer que vous avez lu, compris et accepté de vous
    conformer aux présentes conditions générales et/ou
  </p>
  <p>
    3.3.2. En utilisant ou en continuant d'utiliser et d'exploiter le compte
    RAPIDE FLOAT;
  </p>
  <p>
    3.4. En souscrivant à RAPIDE FLOAT, vous acceptez de respecter et d'être lié
    par les présentes conditions générales pour le moment et, le cas échéant,
    par les lois applicables régissant le fonctionnement du compte RAPIDE FLOAT
    et vous affirmez que les conditions générales énoncées aux présentes sont
    sans préjudice des droits éventuels d'RAPIDE FLOAT sur votre compte.
  </p>
  <p>
    3.5. La Banque se réserve le droit de modifier ces conditions à tout moment
    et l'utilisation continue de votre compte RAPIDE FLOAT constitue votre
    consentement à être lié aux conditions de cette modification.
  </p>
  <p>
    3.6. Vous reconnaissez et acceptez que le compte RAPIDE FLOAT est proposé
    électroniquement et vous acceptez de faire affaire avec la Banque et de
    gérer le compte RAPIDE FLOAT uniquement électroniquement via le menu RAPIDE
    FLOAT sur la plate-forme MTN MOBILE MONEY. Toute demande de renseignements
    ou plainte concernant les services sera adressée au partenaire Financier, en
    appelant le 555 ou en vous rendant dans une agence MTN. La Banque la prend
    en charge, et exécute le traitement approprié de concert avec MTN MOBILE
    MONEY et RAPIDE FLOAT. Afin d'éviter toute ambiguïté, vous reconnaissez et
    acceptez que vous n'êtes pas autorisé à recevoir ou demander des services
    liés au compte RAPIDE FLOAT dans aucune succursale de Rapide Float, à moins
    qu’il n’en décide autrement à sa seule discrétion.
  </p>

  <h2>4. OUVERTURE DE COMPTE</h2>
  <p>
    4.1. Pour ouvrir un compte RAPIDE FLOAT, vous devez avoir au moins 18 ans et
    être abonné à MTN MOBILE MONEY. La Banque se réserve le droit de vérifier
    auprès de MTN CÔTE D’IVOIRE l’authenticité et le statut de votre compte MTN
    MOBILE MONEY ;
  </p>
  <p>
    4.2. Vous pouvez uniquement ouvrir un compte RAPIDE FLOAT par une demande
    effectuée électroniquement à partir votre équipement via le menu RAPIDE
    FLOAT de la plateforme MTN MOBILE MONEY ;
  </p>
  <p>
    4.3. Vous acceptez et autorisez RAPIDE FLOAT à demander à MTN CÔTE D’IVOIRE,
    au nom de MTN MFS CI, toutes les informations personnelles qu’elle détiendra
    de vous conformément à l'accord entre vous et MTN CÔTE D’IVOIRE pour la
    fourniture de services et de produits de MTN CÔTE D’IVOIRE et du service MTN
    MOBILE MONEY, y compris votre numéro de téléphone, votre nom, votre date de
    naissance, le numéro et le type de votre carte d'identité et toute autre
    information permettant à la Banque de vous identifier et de s'y conformer
    avec les exigences réglementaires "Connaitre son client" (ensemble
    "d’informations personnelles"). En outre, vous acceptez et autorisez RAPIDE
    FLOAT à demander à MTN CÔTE D’IVOIRE des informations concernant votre
    utilisation du service MTN MOBILE MONEY et du système MTN MOBILE MONEY,
    comme requis par la Banque, pour vous fournir les Services ("Informations
    MTN MOBILE MONEY" ).Vous consentez par les présentes à la divulgation de vos
    informations personnelles et des informations MTN MOBILE MONEY à la Banque
    pour l'utilisation susmentionnée de vos informations personnelles et des
    informations MTN MOBILE MONEY la Banque ;
  </p>
  <p>
    4.4. Vous consentez et autorisez la Banque à obtenir vos informations
    personnelles auprès de toute source officielle de la Côte d’Ivoire; et vous
    acceptez également et consentez à la divulgation et à la communication de
    ces informations personnelles par le gouvernement de Côte d’Ivoire à la
    Banque;
  </p>
  <p>
    4.5. Vous reconnaissez et autorisez la Banque à comparer vos informations
    personnelles reçues de MTN CÔTE D’IVOIRE conformément à la clause 4.3 avec
    celles du gouvernement du CÔTE D’IVOIRE qui vous concernent, telles que
    contenues dans le NSIS ;
  </p>
  <p>
    4.6. La Banque se réserve le droit de demander des informations
    supplémentaires sur votre demande de compte RAPIDE FLOAT à tout moment. Le
    manque d'informations requises par la microfinance peut entraîner le refus
    de la Banque d'accepter votre demande de compte RAPIDE FLOAT ;
  </p>
  <p>
    4.7. L’acceptation de votre demande d’ouverture d’un compte RAPIDE FLOAT par
    la Banque doit être faite par voie électronique et envoyée au numéro de
    téléphone mobile de MTN CÔTE D’IVOIRE associé à votre compte MTN MONEY. Vous
    reconnaissez que l'approbation par la Banque de l'ouverture de votre compte
    RAPIDE FLOAT ne crée aucune relation contractuelle entre vous et MTN CÔTE
    D’IVOIRE au-delà des conditions générales qui s'appliquent à votre compte
    MTN MOBILE MONEY dans le temps ;
  </p>
  <p>
    4.8. La Banque se réserve le droit de refuser votre demande d'ouverture d'un
    compte RAPIDE FLOAT ou de le révoquer à tout moment à sa seule discrétion et
    sans donner de raison.
  </p>

  <h2>5. TYPES DE COMPTE</h2>
  En tant que titulaire d’un compte RAPIDE FLOAT, vous aurez le droit, sous
  réserve des présentes conditions générales, d’avoir un compte de prêt RAPIDE
  FLOAT.
  <p>
    5.1. Compte de prêt RAPIDE FLOAT : En tant que titulaire d'un compte RAPIDE
    FLOAT, vous pouvez, sous réserve de ces termes et conditions, demander un
    prêt via le menu RAPIDE FLOAT de votre équipement ;
  </p>
  <p>
    5.2. Lorsque vous faites une demande de prêt, votre demande sera évaluée
    conformément au processus d’évaluation de prêt de la Banque. La Banque se
    réserve le droit, à sa seule discrétion et sans indiquer de motif,
    d’approuver ou de rejeter votre demande de prêt ;
  </p>
  <p>
    5.3. Sous réserve d’approbation de votre demande de prêt, La Banque
    déboursera un emprunt d’un montant qu’elle-même décidera, un emprunt d’un
    montant minimum de 50.000 FCFA et d’un montant maximum de 1.500.000 FCFA ou
    de tout autre montant minimal ou maximal que la microfinance peut déterminer
    à tout moment, à sa seule discrétion (le "Prêt") ;
  </p>
  <p>
    5.4. Le montant du prêt sera crédité sur votre compte MTN MOBILE MONEY, sous
    réserve des déductions des frais de transaction applicables ;
  </p>
  <p>
    5.5. Vous devez rembourser le prêt dans un délai d'un (1) jour civil à
    compter de la date de décaissement du prêt ;
  </p>
  <p>5.6. En contrepartie du prêt accordé par la Banque, vous devrez payer :</p>
  <p>
    5.6.1. Des frais de service de 0,294%, relatifs au maintien du compte
    destiné à recevoir les prélèvements effectués au titre de l'amortissement en
    principal du prêt, du règlement des intérêts et des autres charges.
    (Référence : RECUEIL DES TEXTES LEGAUX ET REGLEMENTAIRES REGISSANT
    L'ACTIVITE BANCAIRE ET FINANCIERE DANS L'UNION MONETAIRE OUEST AFRICAINE,
    DECRET RELATIF AU CALCUL DU TAUX EFFECTIF GLOBAL D'INTERET, Article 3) ;
  </p>
  <p>5.6.2 Un taux d’intérêt de 0,036% sont appliqués sur le prêt de 24h ;</p>
  <p>
    5.6.3 Une pénalité de 0,33% du montant restant dû sera appliquée par jour de
    retard de paiement jusqu’au 7è jour d’impayé, du fait de l'inexécution du
    règlement à la date d’échéance ; (Référence : Recueil des textes legaux et
    reglementaires regissant l’activite bancaire et financiere dans l’Union
    Monetaire Ouest Africaine, Decret Relatif au calcul du taux effectif global
    d’interet, Article 3);
  </p>
  <p>
    5.7. La Banque aura le droit de résilier le présent Contrat et de fermer
    votre compte RAPIDE FLOAT conformément aux dispositions de la Section 13,
    sans préjudice de ses droits en vertu du présent Contrat, si vous omettez de
    rembourser les frais de prêt et / ou de transaction dans un délai de
    soixante (60) jours calendaires à compter de la date de décaissement du prêt
    ;
  </p>
  <p>
    5.8. La Banque se réserve le droit de modifier les conditions du Prêt, y
    compris les commissions qui lui sont payées, compte tenu des règles et
    réglementations en vigueur dans le secteur de la microfinance de la zone
    UEMOA ;
  </p>
  <p>
    5.9. La Banque, en partenariat avec MTN CÔTE D’IVOIRE, aura un droit de gage
    et disposera des fonds que vous détenez sur votre compte MTN Mobile Money ;
  </p>
  <p>
    5.10. Vous consentez et autorisez expressément la Banque à divulguer,
    répondre, conseiller, échanger et communiquer des détails ou des
    informations concernant votre compte RAPIDE FLOAT au bureau d’information
    sur le crédit, conformément à la loi.
  </p>

  <h2>
    6. DESCRIPTION DU PROCESSUS D’UTILISATION DU SERVICE DE PRÊT RAPIDE FLOATE
    FLOAT :
  </h2>
  L’utilisation du service de prêt Float Loan comprend les étapes suivantes : -
  Etape 1 : la souscription ; Elle consiste à s’abonner au service de prêt Float
  loan en composant la syntaxe *133#, puis en sélectionnant le point 6 «
  Emprunter Float », puis en sélectionnant à nouveau le point 1 « Rapide Float
  ». Par la suite, les termes et conditions doivent être acceptés en
  sélectionnant le point 1 « Termes et conditions ». - Etape 2 : la demande de
  Float ; Elle consiste, une fois abonné et éligible, à composer la syntaxe
  *133#, puis à sélectionner le point 6 « Emprunter Float », puis à sélectionner
  à nouveau le point 1 « Demande de e – money ». - Etape 3 : le Remboursement du
  Float ; Elle consiste, après avoir emprunté du Float, à composer la syntaxe
  *133#, puis à sélectionner le point 6 « Emprunter Float », puis à sélectionner
  à nouveau le point 2 « Rembourser ».

  <h2>7. FRAIS</h2>
  <p>
    7.1. Vous acceptez par la présente de payer tous les frais de transaction
    découlant de l'utilisation des Services ;
  </p>
  7.2. Vous devez rembourser à MTN CÔTE D’IVOIRE les coûts liés à l'utilisation
  des services et MTN CÔTE D’IVOIRE a le droit de déduire de votre compte RAPIDE
  FLOAT (sans vous en informer au préalable) :
  <p>7.2.1. Tous les frais de transaction liés aux services ;</p>
  <p>
    7.2.2. Tous les frais juridiques, y compris les honoraires d'avocat et de
    client supportés par la Banque pour obtenir des conseils juridiques au nom
    de votre compte RAPIDE FLOAT et les transactions que vous avez engagées pour
    toute procédure judiciaire, arbitrage ou autre résultant de toute
    transaction avec votre compte RAPIDE FLOAT ; et
  </p>
  <p>
    7.2.3. Tous les autres frais, dépenses et taxes, charges et dépenses engagés
    pour satisfaire vos demandes ;
  </p>
  <p>
    7.3. Vous acceptez par la présente de payer les frais et dépenses engagés
    par la Banque pour obtenir ou tenter d'obtenir le paiement de tout emprunt
    dû à votre compte d'emprunt RAPIDE FLOAT ;
  </p>

  <h2>8. DECLARATIONS</h2>
  <p>
    8.1. Vous pouvez envoyer une demande via USSD pour obtenir un relevé ou un
    rapport d'activité concernant votre compte RAPIDE FLOAT via l'option "Mini
    relevé" ;
  </p>
  <p>
    8.2. Un mini relevé RAPIDE FLOAT devra fournir des détails sur les 6 (six)
    dernières transactions de votre compte RAPIDE FLOAT lancées via votre
    équipement ;
  </p>
  <p>
    8.3. Le mini relevé RAPIDE FLOAT ne vous sera pas envoyé au format papier,
    mais plutôt par SMS au numéro de téléphone mobile de MTN CÔTE D’IVOIRE
    associé à votre MTN MOBILE Money. Vous serez Facturés des frais découlant de
    cette action initiée par MTN CÔTE D’IVOIRE lors de la réception de votre
    mini relevé via RAPIDE FLOAT ;
  </p>
  <p>
    8.4. Sauf erreur manifeste, un mini relevé RAPIDE FLOAT ou un relevé de la
    Banque qui vous est envoyé concernant votre compte RAPIDE FLOAT constitue la
    preuve définitive des opérations effectuées sur votre compte RAPIDE FLOAT
    pour la période couverte par le Mini relevé RAPIDE FLOAT.
  </p>

  <h2>9. POUVOIR ABSOLU DE RAPIDE FLOAT</h2>
  <p>
    9.1. Vous autorisez irrévocablement la Banque à traiter toutes les demandes
    de remboursement reçues de vous (ou prétendument de vous) par le biais du
    système et à vous en tenir responsable, même si ces demandes ne sont pas
    autorisées par vous ou faites par vous-même ou non conformes à tous les
    mandats existants donnés par vous.
  </p>
  <p>
    9.2. La Banque peut, à sa seule discrétion, et après avoir reçu une demande
    du client, annuler toute transaction ou instruction, mais sans toutefois s'y
    engager ;
  </p>
  <p>
    9.3. La Banque aura le droit d’accepter et de traiter toute demande, même si
    cette demande est incomplète ou ambiguë, pour quelque raison que ce soit,
    elle peut, à sa discrétion absolue, corriger ces informations sans toutefois
    se référer à vous en cas de besoin ;
  </p>
  <p>
    9.4. La Banque est autorisée à prendre des décisions concernant votre compte
    RAPIDE FLOAT en vertu d'une décision de justice ou d'une autorité ou
    organisme compétent en vertu de la loi applicable ;
  </p>
  <p>
    9.5. En cas de conflit entre les dispositions d'une demande reçue par la
    Banque de votre part et les présentes conditions générales, les présentes
    conditions générales prévaudront.
  </p>

  <h2>10. EQUIPEMENT DU CLIENT ET RESPONSABILITES</h2>
  <p>
    10.1. Vous devez, à vos frais, fournir et maintenir votre équipement en état
    de fonctionnement sûr et efficace pour accéder au système et aux services ;
  </p>
  <p>
    10.2. Vous serez responsable du bon fonctionnement de votre équipement. La
    Banque ne sera en aucun cas responsable des erreurs ou des défaillances
    causées par votre équipement, des virus informatiques ou des problèmes
    connexes pouvant être associés à l'utilisation du Système, des Services et
    de l'Équipement. Vous êtes responsable des honoraires de tout prestataire de
    services et la Banque ne pourra être tenu responsable des pertes ou retards
    causés par ces prestataires ;
  </p>
  <p>
    10.3. Vous devez suivre toutes les instructions, procédures et conditions
    contenues dans les présentes Conditions générales et tous les documents
    fournis par la Banque concernant l’utilisation du Système et des Services ;
  </p>
  <p>
    10.4. Vous acceptez et reconnaissez que vous êtes seul responsable de la
    conservation et de l'utilisation correcte de votre équipement afin de
    préserver le secret et la sécurité de votre Code PIN MTN MOBILE MONEY. Vous
    devez vous assurer que votre Code PIN MTN MOBILE MONEY n'est pas connu ou en
    possession d'un tiers. La Banque ne sera pas responsable de la divulgation
    de votre code PIN MTN MOBILE MONEY à un tiers et vous acceptez par les
    présentes que la Banque ne subira aucune perte résultant de quelque
    information que ce soit concernant le code PIN MTN MOBILE MONEY ;
  </p>
  <p>
    10.5. Vous devez prendre toutes les précautions nécessaires pour détecter
    toute utilisation non autorisée du système et des services. À cette fin,
    vous devez vous assurer que toutes les communications de la Banque sont
    examinées et vérifiées par vous ou en votre nom dès que possible après leur
    réception, de sorte que toute utilisation non autorisée et tout accès au
    système soient détectés ;
  </p>
  <p>
    10.6. Vous devez informer la Banque immédiatement par l'intermédiaire du
    centre de service client si :
  </p>
  <p>
    10.6.1. Vous avez des raisons de croire que votre code PIN MTN MOBILE MONEY
    est ou peut être connu de toute personne non autorisée à le connaître et/ou
    a été compromis ; et où
  </p>
  <p>
    10.6.2. Vous avez des raisons de croire que l'utilisation non autorisée des
    Services est en train ou pourrait se produire et/ou qu'une transaction peut
    avoir été saisie ou compromise frauduleusement ;
  </p>
  <p>
    10.7. Vous devez toujours suivre les procédures de sécurité qui vous sont
    notifiées par la Banque de temps à autre ou toute autre procédure applicable
    aux Services. Vous reconnaissez que tout manquement de votre part à suivre
    les procédures de sécurité recommandées peut entraîner une violation de la
    confidentialité de votre compte RAPIDE FLOAT. En termes clairs, vous devez
    vous assurer que les services ne sont pas utilisés, que les demandes ne sont
    pas faites ou que les fonctions pertinentes ne sont pas exécutées par
    quelqu'un d'autre que la personne autorisée à le faire ;
  </p>
  <p>
    10.8. Vous ne devez à aucun moment exploiter ou utiliser les Services de
    quelque manière que ce soit qui pourrait être préjudiciable à la Banque.
  </p>

  <h2>11. AVERTISSEMENT</h2>
  <p>
    11.1. La Banque ne sera pas tenue responsable des pertes que vous encourriez
    en cas de perturbation ou d'indisponibilité du Service en raison: (a) d'une
    défaillance de votre équipement, ou (b) de toute autre circonstance ne
    relevant pas du domaine de la Banque, sans toutefois s'y limiter, cas de
    force majeure ou d'erreur, d'interruption, de retard ou d'indisponibilité du
    système, action terroriste ou toute action de la part d'un ennemi, panne
    d'équipement, délestage , intempéries ou conditions atmosphériques
    défavorables et défaillance de tout système de télécommunication public ou
    privé ;
  </p>
  <p>
    11.2. la Banque ne sera pas tenue responsable des pertes ou dommages que
    vous pourrez subir du fait ou pour une raison quelconque liée à :
  </p>
  <p>
    11.2.1 L'indisponibilité de fonds suffisants sur votre compte MTN MOBILE
    MONEY et/ou sur votre compte RAPIDE FLOAT ;
  </p>
  <p>
    11.2.2 Panne, dysfonctionnement, interruption ou indisponibilité du système,
    de votre équipement, du réseau, du système MTN MOBILE MONEY et/ou du service
    MTN MOBILE MONEY. Dans ces cas, les conditions générales du service MTN
    MOBILE MONEY s’appliquent;
  </p>
  <p>
    11.2.3 L'argent disponible sur votre compte RAPIDE FLOAT est soumis à une
    procédure légale ou à d'autres restrictions empêchant le paiement, le
    transfert ou le retrait ;
  </p>
  <p>
    11.2.4. Votre incapacité à donner des instructions appropriées ou adéquates
    pour le paiement ou le transfert / retrait relatif à votre compte RAPIDE
    FLOAT
  </p>
  <p>
    11.2.5. Toute utilisation frauduleuse ou illégale des Services, du Système
    et / ou de votre équipement ; ou
  </p>
  <p>
    11.2.6 Votre incapacité à vous conformer aux présentes Conditions générales
    et à tout autre document ou information fourni par la Banque en rapport avec
    l'utilisation du Système et des Services ;
  </p>
  <p>
    11.3. Si, pour une raison autre que celles mentionnées aux paragraphes 11.1
    et 11.2, des interférences interfèrent avec des services qui ne sont donc
    pas disponibles, il incombe à la Banque, en vertu du présent Contrat, de les
    rétablir et cela dans la mesure du possible ;
  </p>
  <p>
    11.4. Sauf disposition contraire de la présente section 10.3, la Banque ne
    pourra être tenu pour responsable de tout autre obstacle ou indisponibilité
    des services, pour quelque motif que ce soit;
  </p>
  <p>
    11.5. La Banque ne peut en aucun cas être tenu pour responsable d’un manque
    à gagner, des économies escomptées, des pertes subies, indirectes ou
    consécutives, de quelque nature que ce soit, résultant de ou liés aux
    Services, même si la possibilité de ces pertes ou dommages est notifiée à la
    Banque;
  </p>
  <p>
    11.6 Toutes les garanties et obligations prévues par la loi sont entièrement
    exclues dans la mesure permise par la loi.
  </p>

  <h2>12. DROITS DE PROPRIÉTÉ INTELLECTUELLE</h2>
  Vous reconnaissez que les droits de propriété intellectuelle du système
  (modifications, mises à jour ou améliorations de ceux-ci de temps à autre) et
  toute documentation mise à votre disposition par RAPIDE FLOAT via son système
  restent la propriété de la Banque et/ou de ses partenaires. Vous ne devez en
  aucun cas interférer avec ces droits de propriété intellectuelle. Vous ne
  pouvez dupliquer, reproduire ou modifier le système et la documentation
  associée sans notification écrite préalable de la Banque.

  <h2>13. CLAUSE DE COMPENSATION</h2>
  <p>
    13.1. La Banque s'engage à respecter vos exigences ou demandes concernant
    votre compte RAPIDE FLOAT. Vous vous engagez à indemniser la Banque et à la
    protéger contre toute perte, charge, dommage, dépense ou réclamation qu'elle
    encourt ou pourrait encourir. Ceci étant dit, vous excluez la Banque de
    toute responsabilité pour les pertes ou dommages que vous pourriez subir du
    fait des actions de cette dernière en application de vos recommandations
    conformément aux présentes Conditions générales ;
  </p>
  <p>
    13.2. La clause d’indemnisation de la clause 13.1 s’applique également aux :
  </p>
  <p>
    13.2.1 Requêtes, réclamations, actions, pertes et dommages de toute nature
    qui pourraient être intentés contre la Banque ou dont elle pourrait être
    victime ou encourus à la suite de son action ou non sur la base d'une
    demande ou d'un dysfonctionnement résultant ou d'une défaillance ou
    indisponibilité de tout matériel informatique , logiciels ou équipements,
    perte ou destruction de données, déchargement, corruption de supports de
    stockage, phénomènes naturels, émeutes, actes de vandalisme, sabotage,
    terrorisme, tout autre événement indépendant de la volonté de la Banque,
    interruption ou perturbation des liens de communication ou en résultant de
    la dépendance de toute personne ou de toute information ou information
    inexacte, illégale, incomplète contenue dans une demande adressée et reçue
    par la Banque;
  </p>
  <p>
    13.2.2 Toute perte ou tout dommage pouvant résulter de votre utilisation,
    mauvaise utilisation, utilisation abusive ou possession d'un logiciel tiers,
    y compris, mais sans s'y limiter, tout système d'exploitation, logiciel de
    navigation ou autre logiciel ou programme ;
  </p>
  <p>
    13.2.3 Tout accès non autorisé à votre compte RAPIDE FLOAT ou toute atteinte
    à la sécurité, destruction ou accès à vos données ou toute destruction, vol
    ou détérioration de l'un de vos équipements ;
  </p>
  <p>
    13.2.4 Toute perte ou tout dommage causé par votre non-respect des
    Conditions générales suivantes et/ou la diffusion d’informations inexactes
    ou par la perte ou le dommage causé par le dysfonctionnement ou
    l’indisponibilité d’un système tiers ou par l’incapacité d’un tiers de
    traiter une transaction ou toute autre perte pouvant être encourue par
    RAPIDE FLOAT à la suite d'une violation des présentes Conditions générales.
  </p>

  <h2>14. CLAUSE SUR LA MODIFICATION ET LA RÉSILIATION DU CONTRAT</h2>
  <p>
    14.1 La Banque peut, à tout moment, vous envoyer un avis mettant fin ou
    modifiant sa relation commerciale avec vous concernant la fermeture de votre
    compte RAPIDE FLOAT de façon particulière. Sans porter préjudice général à
    ce qui précède, la Banque peut annuler les crédits octroyés et exiger le
    remboursement des dettes résultant de cette mesure dans un délai relevant de
    sa responsabilité ;
  </p>
  <p>
    14.2 Sans porter préjudice aux droits de la Banque énoncés à l’article 14.1,
    la Banque peut, à sa seule discrétion, suspendre ou fermer votre compte
    RAPIDE FLOAT :
  </p>
  <p>
    14.2.1 Si vous utilisez le compte RAPIDE FLOAT à des fins non autorisées ou
    si la Banque détecte un usage abusif ou mauvais usage, une violation de
    contenu, une fraude ou une tentative de fraude liée à votre utilisation des
    Services ;
  </p>
  <p>
    14.2.2 S'il arrive que votre compte MTN MOBILE MONEY ou un contrat avec MTN
    CÔTE D’IVOIRE soit résilié pour quelque raison que ce soit ;
  </p>
  <p>
    14.2.3 Si la Banque vient ou est tenu de se conformer à une ordonnance, à
    une instruction ou à une recommandation du gouvernement, du tribunal, de
    l'autorité de réglementation ou de toute autre autorité compétente,
  </p>
  <p>
    14.2.4 Dans le cas où la Banque vous suspecterait d’avoir enfreint les
    présentes conditions générales (y compris le non-paiement du montant du prêt
    que vous devez, le cas échéant) ;
  </p>
  <p>
    14.2.5 Lorsqu'une telle suspension ou modification est nécessaire en raison
    de problèmes techniques ou pour des raisons de sécurité ;
  </p>
  <p>
    14.2.6 Pour simplifier la mise à jour ou la mise à niveau des composants ou
    des fonctionnalités des Services de temps à autre ;
  </p>
  <p>
    14.2.7 Lorsque vous restez inactif pendant une période déterminée à sa seule
    discrétion de la Banque;
  </p>
  <p>
    14.2.8 Si la Banque décide de suspendre ou de cesser de fournir des services
    pour des raisons commerciales ou pour toute autre raison que la Banque
    pourrait déterminer à son entière discrétion ;
  </p>
  <p>
    14.3 Vous pouvez fermer votre compte RAPIDE FLOAT à tout moment via le
    centre de service client de MTN CÔTE D’IVOIRE ;
  </p>
  <p>
    14.4 S'il apparaît que votre compte RAPIDE FLOAT a un solde créditeur au
    moment de la clôture, la Banque vous remboursera ce solde, déduction faite
    des frais applicables. Si, au moment de la fermeture de votre compte RAPIDE
    FLOAT, votre compte de prêt RAPIDE FLOAT est débiteur, vous acceptez de
    payer immédiatement à la Banque tout le montant dû ;
  </p>
  <p>
    14.5 La résiliation n’affectera toutefois pas les droits et obligations
    acquis de l’une des parties ;
  </p>
  <p>
    14.6 Au cas où la Banque recevrait une notification de votre décès, la
    Banque ne sera pas tenu d'autoriser toute transaction ou tout retrait de
    votre compte RAPIDE FLOAT par un tiers, sauf sur présentation de lettres
    d'administration ou de procuration de vos représentants ou personnes morales
    dûment désignées par la Cour de justice ;
  </p>

  <h2>15. CLAUSE RELATIVE À L'INFORMATION</h2>
  15. Vous acceptez expressément et autorisez la Banque à publier, enregistrer,
  recevoir ou utiliser vos informations personnelles ou des informations
  relatives à votre compte RAPIDE FLOAT et tout autre détail relatif à
  l'utilisation des Services :
  <p>
    15.1.1 Vers et depuis tout organisme national ou international chargé de
    l'application de la loi ou un organisme de réglementation ou un organisme
    gouvernemental compétent afin d'aider à la prévention, à la détection, aux
    enquêtes ou aux poursuites en matière de criminalité ou de fraude;
  </p>
  <p>
    15.1.2 Aux fournisseurs de services, revendeurs, agents ou à toute autre
    société de la Banque qui peuvent être ou devenir la filiale ou la société
    financière de la Banque à des fins commerciales appropriées en rapport avec
    les Services ;
  </p>
  <p>15.1.3 Dans un bureau de crédit ;</p>
  <p>
    15.1.4 Aux avocats, auditeurs ou autres conseillers professionnels de la
    Banque ou de tout tribunal judiciaire ou arbitrage dans des procédures
    judiciaires ou d'audit ;
  </p>
  <p>
    15.1.5 MTN CÔTE D’IVOIRE dans le cadre du service MTN MOBILE MONEY et
    d'autres services ;
  </p>
  <p>
    15.1.6. À des fins commerciales appropriées liées à l'utilisation des
    Services, telles que des activités de marketing et de recherche ; et
  </p>
  <p>
    15.1.7. Dans les pratiques commerciales, y compris, mais sans s'y limiter,
    le contrôle de la qualité, la formation et le fonctionnement efficace des
    systèmes.
  </p>

  <h2>16. AUTRES PROVISIONS</h2>
  <p>
    16.1 Les présentes conditions générales (qui peuvent de temps en temps être
    sujettes à modification) constituent un accord juridique qui s'applique à
    vous et à vos héritiers directs ;
  </p>
  <p>
    16.2 Le présent accord et les droits ou obligations en résultant ne
    s'appliquent pas à vous ni à aucune autre personne ;
  </p>
  <p>
    16.3 La Banque peut changer ou modifier à tout moment et sans préavis les
    présentes conditions générales ainsi que les frais de transaction. Ces
    modifications et/ou changements peuvent être publiées sur des affiches ou
    des brochures disponibles dans les agences MTN CÔTE D’IVOIRE, dans les
    quotidiens, sur le site Web de la Banque et/ou de MTN CÔTE D’IVOIRE et/ou
    par tout autre moyen déterminé par la Banque. Tous les changements et
    modifications entreront en vigueur 3 mois après publication.
  </p>
  <p>
    16.4 Aucun manquement ou retard causé par vous ou la Banque dans l'exercice
    d'un droit ou d'un recours en vertu des présentes ne peut faire l'objet
    d'une renonciation ou par l'exercice unique ou partiel d'un droit ou recours
    contre tout autre exercice ou recours d'un droit ;
  </p>
  <p>
    16.5 Les droits et recours prévus aux présentes sont cumulatifs et
    n’excluent pas les droits et recours prévus par la loi ;
  </p>
  <p>
    16.6 Si une disposition de ces conditions générales est examinée par un juge
    d’arbitrage dûment nommé, un tribunal arbitral ou un organe administratif de
    juridiction compétente de nature à invalider ou à rendre inapplicable,
    l’invalidité ou l’inapplicabilité d’une telle disposition n’affecte pas les
    autres dispositions contenues dans ce document ;
  </p>
  <p>
    16.7 Tout changement ou modification de ces Termes et Conditions peut être
    apporté de temps à autre par RAPIDE FLOAT. La notification de tout
    changement ou modification vous sera envoyée par publication comme prévu au
    sous-paragraphe
  </p>
  <p>
    16.3, et s'appliquera de la même manière que celles contenues dans les
    Conditions générales.
  </p>

  <h2>17. AVIS PRÉALABLE</h2>
  <p>
    17.1 La Banque peut vous envoyer des informations sur votre compte RAPIDE
    FLOAT par SMS au numéro de téléphone mobile de MTN CÔTE D’IVOIRE associé à
    votre compte MTN CÔTE D’IVOIRE MOBILE MONEY ;
  </p>
  <p>
    17.2. Aucune réclamation ne pourra être adressée à la Banque pour des
    dommages résultant de pertes, retards, malentendus, mutilations,
    duplications ou toute autre irrégularité due à la transmission de toute
    communication relative au compte RAPIDE FLOAT.
  </p>

  <h2>18. CLAUSE DE RÉSOLUTION DE LITIGES, DE JURIDICTION ET D'ARBITRAGE</h2>
  <p>
    18.1 Vous pouvez contacter le centre de service client de MTN CÔTE D’IVOIRE
    pour signaler tout litige, réclamation ou divergence relatif au compte
    RAPIDE FLOAT ;
  </p>
  <p>
    18.2 Tout litige découlant du présent Contrat ou lié à celui-ci qui n’aura
    pas été résolu par les représentants du Centre de service client de MTN CÔTE
    D’IVOIRE sera soumis à l'arbitrage par un juge unique nommé d'un commun
    accord par les parties ou à défaut du présent contrat, 60 jours à compter de
    la notification du différend, à la demande de l'une des parties, par le
    président du tribunal d'arbitrage et de règlement des différends, qui sera
    le dernier recours ;
  </p>
  <p>
    18.3 Tous les litiges seront réglés conformément aux normes et procédures
    régissant le tribunal de commerce du Côte d’Ivoire;
  </p>
  <p>
    18.4 Le présent accord est régi et interprété conformément aux lois de la
    République du Côte d’Ivoire.
  </p>
</div>
</template>

<script>
export default {
  name: "termsFr",
  mounted() {
            this.init();
        },
        methods: {
            init() {

                this.scrollToTop();
            },
            scrollToTop() {
            window.scrollTo(0, 0);
            },

            /*----PRELOADER-----*/
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            }

        }

};

</script>