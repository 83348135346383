<template>
    <div id="app">
        <div id="main-wrapper">
            <!-- Start Header -->
            <medpill_header :onTerms="false"></medpill_header>
            <router-view />
            <!-- Start footer -->
            <medpill_footer></medpill_footer>
        </div>
    </div>
</template>
<!-- Material Icon CSS -->
<style src="./assets/css/materialdesignicons.min.css"></style>
<!-- Main Style CSS -->
<style lang="scss" src="./assets/sass/style.scss"></style>

<!-- Main App Js -->
<script src="./app.js"></script>