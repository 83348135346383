<template>
  <div>
    <!-- Home Start-->
    <section class="theme-bg overflow-hidden home-section" id="home">
      <div id="particles-js"></div>
      <div class="waves-bg-img home-bg">
        <div class="circle-clip">
          <img
            src="assets/images/slider-img/pattern-1.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="square-clip">
          <img
            src="assets/images/slider-img/pattern-2.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="container">
          <div class="owl-carousel owl-theme main-slider">
            <div class="item">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div class="content-fadeInUp">
                    <h2 class="heading">Banking Mobile Money Agents & SMEs</h2>
                    <p class="para-txt">
                      We provide 24/7 working capital for mobile money agents
                      and SMEs. We bridge the liquidity gap by providing
                      immediate adequate float to mobile money agents and SMEs
                    </p>
                    <div class="learn-more">
                      <a
                        href="mailto:ir@robofloat.com"
                        class="btn btn-white rounded-pill text-white"
                        >Contact Us</a
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="img-fadeInRight">
                    <img
                      style="margin-left: 64px"
                      src="/assets/images/black-person-with-phone.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="item">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="content-fadeInUp">
                                        <h2 class="heading">
                                            Beautiful Vibrant & Modern Design
                                        </h2>
                                        <p class="para-txt">
                                            A small river named Duden flows by their place and supplies it with the necessary regelialia.
                                        </p>
                                        <div class="learn-more">
                                            <a href="#aboutus" class="btn btn-white rounded-pill text-white">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="img-fadeInRight">
                                        <img src="assets/images/slider-img/My project.png" class="img-fluid" alt="">
                                    </div>
                                </div>
                            </div>
                        </div> -->
          </div>
        </div>
      </div>
    </section>
    <!-- Home End -->
    <!-- Start Features -->
    <section class="section features-section overflow-hidden" id="features">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="app-feature-box">
              <div class="feature-inner">
                <div class="app-feature-main">
                  <div class="app-feature-inner">
                    <div class="icon-block">
                      <i class="icon mdi mdi-rocket"></i>
                    </div>
                    <h5>Instant</h5>
                    <div class="text">Access to float.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="app-feature-box">
              <div class="feature-inner">
                <div class="app-feature-main">
                  <div class="app-feature-inner">
                    <div class="icon-block">
                      <span class="icon mdi mdi-collage"></span>
                    </div>
                    <h5>Convenient</h5>
                    <div class="text">
                      USSD channel to request and receive working capital.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="app-feature-box">
              <div class="feature-inner">
                <div class="app-feature-main">
                  <div class="app-feature-inner">
                    <div class="icon-block">
                      <span class="icon mdi mdi-bullhorn"></span>
                    </div>
                    <h5>Increased</h5>
                    <div class="text">
                      Transaction volume and amounts with more access to larger
                      set of customers.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="featured-info col-lg-8 col-md-12 col-sm-12">
            <!-- <div class="featured-inner">
                            <div class="section-title mb-4">
                                <h2>User friendly interfaces</h2>
                                <p class="section_subtitle">Lorem Ipsum is simply dummy text of the printing us. disciplined rid so rational muff the project.</p>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="featured-list-block">
                                        <div class="featured-list-inner">
                                            <div class="icon-block">
                                                <span class="icon mdi mdi-brush"></span>
                                            </div>
                                            <h4>Elegant interface</h4>
                                            <div class="text">Lorem Ipsum is simply dummy text of the printing us.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="featured-list-block">
                                        <div class="featured-list-inner">
                                            <div class="icon-block light-blue">
                                                <span class="icon mdi mdi-lock"></span>
                                            </div>
                                            <h4>Easy Access</h4>
                                            <div class="text">Lorem Ipsum is simply dummy text of the printing us.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="featured-list-block">
                                        <div class="featured-list-inner">
                                            <div class="icon-block blue">
                                                <span class="icon mdi mdi-update"></span>
                                            </div>
                                            <h4>Update Regularly</h4>
                                            <div class="text">Lorem Ipsum is simply dummy text of the printing us.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="featured-list-block">
                                        <div class="featured-list-inner">
                                            <div class="icon-block sky-blue">
                                                <span class="icon mdi mdi-account-network"></span>
                                            </div>
                                            <h4>Easy Sharing</h4>
                                            <div class="text">Lorem Ipsum is simply dummy text of the printing us.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
          </div>
          <!-- <div class=" col-lg-4 col-md-12 col-sm-12">
                        <div class="image-block">
                            <div class="inner-column">
                                <div class="circles-img"></div>
                                <div class="image text-center">
                                    <img src="assets/images/features/mobile-13.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
    </section>
    <!-- End Features -->
    <!-- Start About-Us -->
    <section class="section bg-light" id="aboutus">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12 p-0 pr-sm-5">
            <div class="img-box">
              <img src="assets/images/Wood-Hand.png" class="img-fluid" alt="" />
            </div>
          </div>
          <div class="col-lg-5 ml-auto col-md-12">
            <div class="section-title mb-4">
              <h2>Our service is delivered solely via the mobile phone</h2>
              <p class="section_subtitle">
                Our service is 100% mobile phone based. Ease of access to quick
                funding anywhere and anytime is our hallmark.
              </p>
            </div>
            <!-- <ul class="list-icon pl-3">
                                <li class="mb-4"><i class="la la-check"></i> Deos et accusamus et iusto odio</li>
                                <li class="mb-4"><i class="la la-check"></i> Distinctively brand adaptive innovation</li>
                                <li><i class="la la-check"></i> Molestias a orci facilisis rutrum</li>
                            </ul> -->
          </div>
        </div>
      </div>
    </section>
    <!-- End About-Us -->
    <!-- Start How-it-Work -->
    <!-- <section class="section" id="howitworks">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title text-center mb-4">
                            <h2>How It Work?</h2>
                            <p class="section_subtitle mx-auto">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                        </div>
                    </div>
                </div>
                <div class="how-works-block mt-4">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="how-works-box">
                                <img src="assets/images/works/1.png" class="img-fluid mx-auto d-block" alt="">
                                <div class="text-center">
                                    <h4 class="mt-4">Choose a plan</h4>
                                    <p class="mb-0 mt-4">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="how-works-box">
                                <img src="assets/images/works/2.png" class="img-fluid mx-auto d-block" alt="">
                                <div class="text-center">
                                    <h4 class="mt-4">Payment method</h4>
                                    <p class="mb-0 mt-4">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="how-works-box m-box-0">
                                <img src="assets/images/works/3.png" class="img-fluid mx-auto d-block" alt="">
                                <div class="text-center">
                                    <h4 class="mt-4">Let's work</h4>
                                    <p class="mb-0 mt-4">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
    <!-- End How-it-Work -->
    <!--Start Showcase-->
    <!-- <section class="section bg-light" id="showcase">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title text-center mb-4">
                            <h2>Showcase</h2>
                            <p class="section_subtitle mx-auto">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="owl-carousel owl-theme showcase-slider">
                            <div class="item">
                                <div class="showcase-card">
                                    <img src="assets/images/screenshots/app-screen-1.jpg" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <div class="showcase-card">
                                    <img src="assets/images/screenshots/app-screen-2.jpg" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <div class="showcase-card">
                                    <img src="assets/images/screenshots/app-screen-3.jpg" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <div class="showcase-card">
                                    <img src="assets/images/screenshots/app-screen-4.jpg" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <div class="showcase-card">
                                    <img src="assets/images/screenshots/app-screen-5.jpg" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <div class="showcase-card">
                                    <img src="assets/images/screenshots/app-screen-6.jpg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
    <!--End Showcase-->
    <!-- Start Testimonial -->
    <!-- <section class="section bg-gradient" id="client">
            <div id="particles-js1">
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title text-center mb-4">
                            <h2 class="text-white">What People Say</h2>
                            <p class="text-white section_subtitle mx-auto">Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="owl-carousel owl-theme testimonial-slider">
                            <div class="item">
                                <div class="testimonial-card shadow-md">
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,</p>
                                </div>
                                <div class="user-txt">
                                    <div class="user-pics"><img src="assets/images/testi/testi-1.png" alt=""></div>
                                    <div class="use-info">
                                        <h6 class="heading text-white">Jack Jordan</h6>
                                        <p class="sub-heading text-white">Mediapp User</p>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="testimonial-card shadow-md">
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,</p>
                                </div>
                                <div class="user-txt">
                                    <div class="user-pics"><img src="assets/images/testi/testi-1.png" alt=""></div>
                                    <div class="user-info">
                                        <h6 class="heading text-white">Jack Jordan</h6>
                                        <p class="sub-heading text-white">Mediapp User</p>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="testimonial-card shadow-md">
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,</p>
                                </div>
                                <div class="user-txt">
                                    <div class="user-pics"><img src="assets/images/testi/testi-1.png" alt=""></div>
                                    <div class="user-info">
                                        <h6 class="heading text-white">Jack Jordan</h6>
                                        <p class="sub-heading text-white">Mediapp User</p>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="testimonial-card shadow-md">
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,</p>
                                </div>
                                <div class="user-txt">
                                    <div class="user-pics"><img src="assets/images/testi/testi-1.png" alt=""></div>
                                    <div class="user-info">
                                        <h6 class="heading text-white">Jack Jordan</h6>
                                        <p class="sub-heading text-white">Mediapp User</p>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="testimonial-card shadow-md">
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,</p>
                                </div>
                                <div class="user-txt">
                                    <div class="user-pics"><img src="assets/images/testi/testi-1.png" alt=""></div>
                                    <div class="user-info">
                                        <h6 class="heading text-white">Jack Jordan</h6>
                                        <p class="sub-heading text-white">Mediapp User</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
    <!-- End Testimonial -->
    <!-- Start Price -->
    <!-- <section class="section" id="price">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title text-center mb-4">
                            <h2>Choose Your Pricing Plan</h2>
                            <p class="section_subtitle mx-auto">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-4 justify-content-center">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="package-box">
                            <div class="package-box-inner">
                                <div class="package-hover-lines"></div>
                                <div class="package-title">Starter</div>
                                <h2>$15.00</h2>
                                <ul class="package-list">
                                    <li>Single License</li>
                                    <li>0 Team Members</li>
                                    <li>99 mb of Storage</li>
                                    <li>1.00 Project</li>
                                </ul>
                                <a href="#" class="theme-btn rounded-pill">get started</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="package-box">
                            <div class="package-box-inner">
                                <div class="package-hover-lines"></div>
                                <div class="package-title">Proffesional</div>
                                <h2>$15.00</h2>
                                <ul class="package-list">
                                    <li>Single License</li>
                                    <li>0 Team Members</li>
                                    <li>99 mb of Storage</li>
                                    <li>1.00 Project</li>
                                </ul>
                                <a href="#" class="theme-btn rounded-pill">get started</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <div class="package-box m-box-0">
                            <div class="package-box-inner">
                                <div class="package-hover-lines"></div>
                                <div class="package-title">Business</div>
                                <h2>$15.00</h2>
                                <ul class="package-list">
                                    <li>Single License</li>
                                    <li>0 Team Members</li>
                                    <li>99 mb of Storage</li>
                                    <li>1.00 Project</li>
                                </ul>
                                <a href="#" class="theme-btn rounded-pill">get started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
    <!-- End Price -->
    <!-- Start Parallax -->
    <!-- <section class="parallax parallax-box section" id="contact">
            <div class="parallax-content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-title text-center mb-4 z-index-9">
                                <h2 class="text-white">Get Quote</h2>
                                <p class="section_subtitle mx-auto text-white">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-sm-12 mx-auto">
                            <div class="mt-4 contact_form">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <input name="name" id="name" type="text" class="form-control" placeholder="Your name...">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <input name="email" id="email" type="email" class="form-control" placeholder="Your email...">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <textarea name="comments" id="comments" rows="4" class="form-control" placeholder="Your message..."></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 text-center">
                                            <input type="submit" class="btn btn-gradient text-white w-100 text-uppercase" value="Sign Up for free">
                                            <p class="mt-4 mb-0">30 Days Trial. Free Account Creating.</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
    <!-- End Parallax -->
    <!-- Start FAQ's -->
    <!-- <section id="faq" class="section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title text-center mb-4">
                            <h2>Frequently Asked</h2>
                            <p class="section_subtitle mx-auto">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis</p>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div id="accordion" class="faq-accordion-panel">
                            <div class="question-card shadow-sm active">
                                <div class="card-header p-0" id="headingOne">
                                    <h4 class="mb-0">
                                        <button class="btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"><span>1. Lorem Ipsum Dolor Sit?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingTwo">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><span>2. Amet Consectetur Adipisicing?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingThree">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"><span>3. Elit Sed Do Eiusmo?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingFour">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"><span>4. Ea Commodo Consequat?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img src="assets/images/faqs.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </section> -->
    <!-- End FAQ's -->
  </div>
</template>
<script lang="ts" setup></script>
