<template>
  <div>
    <!-- Start Footer -->
    <footer class="footer theme-bg overflow-hidden pb-4">
      <div class="container footer-top mb-4">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center mb-4">
              <!-- <h2 class="text-white">Download App Now</h2>
                            <p class="text-white section_subtitle mx-auto">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="apps-btns">
              <!-- <a class="btn store-icon-box" href="#">
                                <i class="mdi mdi-apple" aria-hidden="true"></i><p>App Store</p>
                            </a>
                            <a class="btn store-icon-box" href="#">
                                <i class="mdi mdi-google-play" aria-hidden="true"></i><p>GOOGLE PLAY</p>
                            </a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="container footer-bottom">
        <div class="row">
          <div class="col-lg-4">
            <div class="foot_logo">
              <img
                style="height: 90px"
                src="assets/images/logo-dark.svg"
                class="img-fluid d-block"
                alt=""
              />
            </div>
            <p class="mt-4 text-left ftr-about">
              We bridge the liquidity gap by providing immediate adequate float
              to mobile money agents and SMEs
            </p>
            <div class="mt-4">
              <ul class="fot_social list-inline mt-4">
                <!-- <li class="list-inline-item">
                  <a href="#" class="social-icon"
                    ><i class="mdi mdi-facebook"></i
                  ></a>
                </li>
                <li class="list-inline-item">
                  <a href="#" class="social-icon"
                    ><i class="mdi mdi-twitter"></i
                  ></a>
                </li> -->
                <li class="list-inline-item">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/robofloat/"
                    class="social-icon"
                    ><i class="mdi mdi-linkedin"></i
                  ></a>
                  <!-- </li>
                <li class="list-inline-item">
                  <a href="#" class="social-icon"
                    ><i class="mdi mdi-google-plus"></i
                  ></a>
                </li> -->
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-sm-4">
            <h5 class="footer-title">Quick Links</h5>
            <ul class="footer_menu_list list-unstyled mb-0 mt-4">
              <li style="margin-right: 20px"><router-link to="/">Home </router-link></li>
              <li style="margin-right: 20px">
                <router-link :to="{name: 'TermsFr'}">Terms and Conditions Fr</router-link>
              </li>
              <li style="margin-right: 20px">
                <router-link :to="{name: 'TermsEn'}">Terms and Conditions En</router-link>
              </li>
              <!-- <li><a href="#">Client</a></li>
                            <li><a href="#">Contact</a></li> -->
            </ul>
          </div>
          <!-- <div class="col-lg-2 col-sm-4">
                        <h5 class="footer-title">Support</h5>
                        <ul class="footer_menu_list list-unstyled mb-0 mt-4">
                            <li><a href="#">Help & Support</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Terms & Conditions</a></li>
                        </ul>
                    </div> -->
          <div class="col-lg-4 col-sm-4">
            <h5 class="footer-title">Quick Contact</h5>
            <ul
              class="footer_menu_list list-unstyled mb-0 mt-4 contact-menu-list"
            >
              <li>
                <i class="md-icon mdi mdi-map-marker"></i>
                <a href="https://www.google.com/maps" target="_blank">
                  Alta Gracias, non loin de la nouvelle ambassade de Chine
                  Cocody, Riviera M’badon, Abidjan <br /><br />
                  Countries: Ivory Coast, Uganda, Benin, Liberia, Burkina Faso,
                  Niger <br /><br />
                </a>
              </li>
              <li>
                <i class="md-icon mdi mdi-email-outline"></i
                ><a href="mailto:ir@robofloat.com">ir@robofloat.com</a>
              </li>
              <li>
                <i class="md-icon mdi mdi-phone-in-talk"></i
                ><a href="tel:+12453698741">+2250554169780</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-12">
            <div class="foot_desc mt-4 pt-4">
              <p class="mb-0 text-center">
                2024 &copy;
                <span class="text-white font-weight-bold">Robofloat</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- End Footer -->
    <!-- Back To Top -->
    <a href="#" class="back_top"> <i class="mdi mdi-chevron-up"></i></a>
  </div>
</template>

<script>
export default {
  name: "medpill_header",
  methods: {},
};
</script>
